import React from 'react'
const containerStyle = {
  fontFamily: 'Roboto, Arial, sans-serif',
  textAlign: 'center',
  padding: '20px',
  backgroundColor: '#000', // Fondo negro
  color: '#fff', // Texto blanco
  marginBottom: '2rem',
  borderRadius: '10px'
};

const titleStyle = {
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '20px',
  textTransform: 'uppercase',
  letterSpacing: '1px',
  lineHeight: '1.4',
};

const VideoGraph = ({children, text}) => {
  return (
    <div style={containerStyle}>
      <div style={titleStyle}>
        {text}
      </div>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        {children}
      </div>
    </div>
  )
}

export default VideoGraph